/* --------------------------------
    SITE SIPAUI
    CSS d’impression
 -------------------------------- */

header nav,
main > aside,
#outils,
.nav-interne,
#remonter {
    display: none !important;
}

html {
    font-size: 45%;
}

header {
    margin-bottom: 1cm;
}

h1,
.su-h1 {
    font-size: 25pt !important;
    
    .francais {
        color: var(--anthracite);
    }
}
